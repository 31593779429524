import React from 'react'
import gql from 'graphql-tag'
import { withRouter } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'

import contractGraphQL from './graphql/contract'
import { getContractsQuery } from './Contracts'
import Container from '../Container'
import Contract from './Contract'
import Intro from './Intro'
import Title from '../Title'
import convertDateToMidnight from './utils/convertDateToMidnight'
import convertDateToUTC from './utils/convertDateToUTC'

const addContractMutation = gql`
  mutation addContract($contract: ContractInput!) {
    addContract(contract: $contract) {
      ...Contract
    }
  }
  ${contractGraphQL}
`

const initalContract = {
  bexioContactID: null,
  product: {
    id: null,
    price: 0,
    pricePurchase: 0,
  },
  duration: 'YEAR',
  quantity: 1,
  startDate: convertDateToMidnight(new Date()),
  endDate: null,
  marginPercent: 0,
  notes: '',
  description: '',
}

const CreateContract = ({ history }) => {
  const [addContract] = useMutation(addContractMutation)

  const onSubmit = (contract) => {
    // TODO: Cleaner solution? Intention was to not need to send nested object (contract.product.id) ...
    // TODO: ... but probably nested object is cleaner, if later contracts will support multiple products. Same goes for contact: contract.contact.id.
    // TODO: ... same in UpdateContract.js

    // Remove contract.product object and create productId instead.
    contract.productId = contract.product.id
    delete contract.product

    addContract({
      variables: {
        contract: {
          ...contract,
          startDate: convertDateToUTC(contract.startDate), // convert date to midnight that day.
          endDate: contract.endDate
            ? convertDateToUTC(contract.endDate)
            : undefined,
        },
      },
      refetchQueries: [
        {
          query: getContractsQuery,
        },
      ],
    })
    history.push('/contracts')
  }

  return (
    <Container width="wide">
      <Intro>
        <Title>Vertrag hinzufügen</Title>
      </Intro>
      <Contract contract={initalContract} onSubmit={onSubmit} />
    </Container>
  )
}

export default withRouter(CreateContract)
