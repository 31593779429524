import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Input from './Input'
import Button from './Button'
import logoImg from '../assets/Logo_v2.svg'

import { useAuth } from '../utils/auth'

const Wrapper = styled.div`
  background: #fff;
  max-width: 100%;
  width: 860px;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
  height: calc(100vh - 64px);
  display: flex;
  align-items: center;
  justify-content: center;
`

const Row = styled.div`
  margin-bottom: 1rem;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  margin-left: -24px;
  margin-right: -24px;
`

const Col = styled.div`
  width: 50%;
  padding: 0 24px;
`

const LogoImg = styled.img`
  display: block;
  height: 80px;
  width: auto;
  margin-left: auto;
  margin-top: 4px;
`

const AUTH_MUTATION = gql`
  mutation Auth($email: String!, $password: String!) {
    auth(email: $email, password: $password) {
      token
      user {
        id
        firstname
        lastname
        role
      }
    }
  }
`

const Login = ({ history }) => {
  const [authMutation] = useMutation(AUTH_MUTATION)

  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  })

  const [auth, setAuth] = useAuth()

  if (auth) {
    history.push('/')
  }

  // TODO: Handle/show errors

  const onLogin = (e) => {
    e.preventDefault()
    authMutation({
      variables: { email: credentials.email, password: credentials.password },
    }).then((result) => {
      if (result.data.auth) {
        const { token, user } = result.data.auth
        setAuth({ token: token, user: user })
        // TODO: Redirect to where user was before
        history.push('/')
      } else {
        // TODO: Show error message.
        alert('Nicht erfolgreich. Bitte erneut versuchen.')
      }
    })
  }

  const onChange = (key, value) => {
    setCredentials({
      ...credentials,
      [key]: value,
    })
  }

  return (
    <Wrapper>
      <Content>
        <Col>
          <LogoImg src={logoImg} alt="Tickets" />
        </Col>
        <Col>
          <form onSubmit={onLogin}>
            <Row>
              <Input
                type="email"
                value={credentials.email}
                placeholder="E-Mail"
                onChange={(e) => onChange('email', e.target.value)}
              />
            </Row>
            <Row>
              <Input
                type="password"
                value={credentials.password}
                placeholder="Passwort"
                onChange={(e) => onChange('password', e.target.value)}
              />
            </Row>
            <Row>
              <Button type="submit">Anmelden</Button>
            </Row>
          </form>
        </Col>
      </Content>
    </Wrapper>
  )
}

export default Login
