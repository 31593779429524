import React from 'react'
import styled from 'styled-components/macro'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import ColumnHeader from './ColumnHeader'
import TicketPreview from './TicketPreview'
import { filterBySearch, filterByUsers } from '../utils/filter'

const Wrapper = styled.div`
  padding: 0;
  display: flex;
  width: 100%;
`

const Header = styled.header`
  position: relative;
  height: 64px;
`

const Container = styled.div`
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  background: #f7f7f7;
`

const Item = styled.div`
  max-width: 100%;
  width: 25%;
  padding: 6px;
`

// TODO: Use Fragment
const GET_TICKETS_QUERY = gql`
  query GetTickets($columnId: ID) {
    getTickets(columnId: $columnId) {
      id
      title
      description
      sort
      column {
        id
      }
      creationDate
      dueDate
      assignedUser {
        id
        firstname
        lastname
        color
      }
      contact {
        id
        bexioId
        name
      }
      isMarked
    }
  }
`

const TicketArchive = ({ search, users }) => {
  const { data, loading, error } = useQuery(GET_TICKETS_QUERY, {
    variables: { columnId: 5 },
  })

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error! {error.message}</div>
  }

  let filteredTickets = data.getTickets

  if (search) {
    filteredTickets = filterBySearch(filteredTickets, search)
  }

  if (users) {
    filteredTickets = filterByUsers(filteredTickets, users)
  }

  return (
    <Wrapper>
      <Container>
        <Header>
          <ColumnHeader
            title="Archiv"
            count={filteredTickets.length}
            meta={
              <Link to="/">
                <FontAwesomeIcon icon={['far', 'compress']} />
              </Link>
            }
          />
        </Header>
        <Content>
          {filteredTickets.map((ticket) => (
            <Item key={ticket.id}>
              <TicketPreview key={ticket.id} ticket={ticket} />
            </Item>
          ))}
        </Content>
      </Container>
    </Wrapper>
  )
}

export default TicketArchive
