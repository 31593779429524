import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'

export const Editor = ({ value, onChange, placeholder, ...props }) => {
  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder,
        }),
      ],
      content: value,

      onBlur: ({ editor }) => {
        const html = editor.getHTML()
        const finalHtml = html.replaceAll(
          /<li><p>(.*?)<\/p><(\/?)(ol|li|ul)>/gi,
          '<li>$1<$2$3>'
        )
        onChange(finalHtml)
      },
    },
    [value]
  )

  return <EditorContent editor={editor} {...props} />
}
