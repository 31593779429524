export default function getUnitById(units, id) {
  if (id) {
    for (const unit of units) {
      if (unit.id === id.toString()) {
        return unit.name
      }
    }
  }

  return ''
}
