import React from 'react'
import styled from 'styled-components'

import Select from '../Select'

const Wrapper = styled.div`
  width: 200px;
`

const SelectContact = ({ contacts, selectedBexioId, onSelect }) => {
  // Info: contact[i].id is the Bexio ID.

  const options = contacts.map((contact) => {
    return {
      value: contact.id,
      label: contact.name,
    }
  })

  const option = contacts.find((contact) => contact.id === selectedBexioId)
  const selectedOption = option
    ? {
        value: option.id,
        label: option.name,
      }
    : ''

  const onChange = (option) => {
    onSelect(option.value)
  }

  return (
    <Wrapper>
      {contacts && (
        <Select
          placeholder="Kontakt..."
          options={options}
          selectedOption={selectedOption || null}
          onChange={onChange}
        />
        /*
        <Select
          onChange={e => onSelect(e.target.value)}
          value={selectedBexioId || ''}
        >
          <Select.Option>Bitte wählen...</Select.Option>
          {contacts.map(contact => (
            <Select.Option key={contact.id} value={contact.id}>
              {contact.name}
            </Select.Option>
          ))}
        </Select>
        */
      )}
    </Wrapper>
  )
}

export default SelectContact
