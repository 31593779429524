import React from 'react'
import styled, { css } from 'styled-components/macro'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark as faBookmarkSolid } from '@fortawesome/pro-solid-svg-icons'

import Avatar from './User/Avatar'
import * as date from '../utils/date'

const Wrapper = styled.div`
  padding: 12px 14px 14px 14px;
  background: #ffff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 1rem;
  cursor: pointer;
  position: relative;
`

const Title = styled.div`
  font-weight: 500;
  margin-bottom: 0.125rem;
  font-size: 15px;
`

const Description = styled.div`
  margin-bottom: 0.75rem;
  font-size: 12px;
  line-height: 1.2;
`

const Meta = styled.div`
  font-size: 12px;
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  line-height: 1.1;
`

const MetaItem = styled.div`
  margin: 0 10px;

  ${(props) =>
    props.close &&
    css`
      margin-right: 0;
    `}
`

const MetaItemLabel = styled.div`
  font-weight: 700;
  opacity: 0.5;
  margin-bottom: 6px;
  font-size: 11px;
`

const MetaItemContent = styled.div`
  ${(props) =>
    props.warning &&
    css`
      color: #e74c3c;
    `}
`

const Mark = styled.div`
  position: absolute;
  top: -4px;
  right: 16px;
`

/*
const MetaIcon = styled.div`
  display: inline-block;
  margin-right: 6px;
  position: relative;
  top: -1px;
`
*/

const TicketPreview = ({ ticket, history, index }) => {
  const onClick = () => {
    history.push(`/tickets/${ticket.id}`)
  }

  return (
    <Wrapper onClick={onClick} key={ticket.id}>
      {ticket.contact && (
        <Title key={ticket.contact?.name}>{ticket.contact.name}</Title>
      )}
      <Description>{ticket.title}</Description>
      <Mark>
        {ticket.isMarked && (
          <FontAwesomeIcon icon={faBookmarkSolid} color="#e74c3c" />
        )}
      </Mark>
      <Meta>
        <MetaItem close>
          <Avatar user={ticket.assignedUser} />
        </MetaItem>
        <MetaItem importance="LOW">
          <MetaItemLabel>Erstellt</MetaItemLabel>
          <MetaItemContent>{date.format(ticket.creationDate)}</MetaItemContent>
        </MetaItem>
        {ticket.bexioInvoiceId && (
          <MetaItem>
            <MetaItemLabel>&nbsp;</MetaItemLabel>
            <MetaItemContent>
              <FontAwesomeIcon icon={['far', 'file-invoice-dollar']} />
            </MetaItemContent>
          </MetaItem>
        )}
      </Meta>
    </Wrapper>
  )
}

TicketPreview.propTypes = {
  ticket: PropTypes.object,
  history: PropTypes.object,
}

export default withRouter(TicketPreview)
