import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import gql from 'graphql-tag'
import { sortBy } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@apollo/client'

import Label from '../Label'
import Button from '../Button'
import AddContact from './AddContact'
import SelectContact from './SelectContact'
import ShowContact from './ShowContact'

const Wrapper = styled.div``

const Cols = styled.div`
  display: flex;
  margin: 0 -12px;
`

const Col = styled.div`
  padding: 0 12px;
`

export const GET_CONTACTS = gql`
  query GetContacts {
    getContacts {
      id
      name
      number
      bexioId
    }
  }
`

/** Contact loads contacts and decides which view to show.  */
// TODO: Move assignContact mutation and contacts query to respective view?
const Contact = ({ contact, onSelect }) => {
  const [currentView, setCurrentView] = useState(contact ? 'SHOW' : 'SELECT')

  const [selectedBexioId, setSelectedBexioId] = useState(
    contact ? contact : null
  )

  const { loading, data, error } = useQuery(GET_CONTACTS, {
    fetchPolicy: 'no-cache',
  })

  const onAddContactCancel = () => {
    setCurrentView('SELECT')
  }

  const onAddContact = () => {
    setCurrentView('ADD')
  }

  // TODO: Move all functions (where possible) out of render method? (performance)
  const sortContacts = (contacts) => {
    return sortBy(contacts, ['name'])
  }

  const onContactSelect = (bexioId) => {
    setSelectedBexioId(bexioId)
    setCurrentView('SHOW')

    // TODO: Update ticket on update.
    onSelect(bexioId)
  }

  const onChangeContact = () => {
    setCurrentView('SELECT')
  }

  if (error) {
    return <div>Fehler: {error.message}</div>
  }

  // if (loading) {
  //   return <div>Wird von Bexio geladen...</div>
  // }

  return (
    <Wrapper key={selectedBexioId}>
      <Label>Kontakt</Label>
      {currentView === 'SELECT' && (
        <Cols>
          <Col>
            <SelectContact
              contacts={sortContacts(data?.getContacts || [])}
              onSelect={onContactSelect}
              selectedBexioId={selectedBexioId}
            />
          </Col>
          <Col>
            <Button icon onClick={onAddContact}>
              <FontAwesomeIcon icon={['far', 'plus']} />
            </Button>
          </Col>
        </Cols>
      )}
      {currentView === 'ADD' && (
        <AddContact onCancel={onAddContactCancel} onAdded={onContactSelect} />
      )}
      {currentView === 'SHOW' && (
        <Cols>
          <Col>
            <ShowContact bexioId={selectedBexioId} key={selectedBexioId} />
          </Col>
          <Col>
            <Button onClick={onChangeContact}>
              <FontAwesomeIcon icon={['far', 'pencil']} />
            </Button>
          </Col>
        </Cols>
      )}
    </Wrapper>
  )
}

Contact.propTypes = {
  contact: PropTypes.string,
}

export default Contact
