import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'

import Label from '../Label'
import Input from '../Input'
import Radio from '../Radio'
import Select from '../Select'
import Button from '../Button'

import { GET_CONTACTS } from './Contact'

const Wrapper = styled.div`
  max-width: 480px;
`

const Cols = styled.div`
  display: flex;
  margin: 0 -12px;
`

const Col = styled.div`
  width: 100%;
  padding: 0 12px;
  margin-bottom: 12px;

  ${(props) =>
    props.width &&
    css`
      width: ${(100 / 12) * props.width}%;
    `}
`

const ADD_CONTACT_MUTATION = gql`
  mutation AddContact($contact: ContactInput!) {
    addContact(contact: $contact) {
      id
      bexioId
      name
    }
  }
`

const GET_COUNTRIES = gql`
  query GetCountries {
    getCountries {
      id
      name
    }
  }
`

const AddContact = ({ onCancel, onAdded }) => {
  const [contact, setContact] = useState({ type: 'COMPANY' })
  const [addContact] = useMutation(ADD_CONTACT_MUTATION)
  const { data: getCountriesData } = useQuery(GET_COUNTRIES)

  const onTypeChange = (e) => {
    setContact({
      ...contact,
      type: e.target.value,
    })
  }

  const onChangeContact = (key, value) => {
    setContact({
      ...contact,
      [key]: value,
    })
  }

  const countryOptions =
    getCountriesData?.getCountries?.map((country) => ({
      value: country.id,
      label: country.name,
    })) || []

  const selectedCountry = countryOptions.find(
    (country) => country.value === contact.countryId
  )

  const onSubmit = (e) => {
    e.preventDefault()
    addContact({
      variables: { contact },
      update: (cache, { data: { addContact } }) => {
        const { getContacts } = cache.readQuery({ query: GET_CONTACTS })
        cache.writeQuery({
          query: GET_CONTACTS,
          data: { getContacts: getContacts.concat([addContact]) },
        })
      },
    }).then((response) => {
      onAdded(response.data.addContact.bexioId)
    })
  }

  return (
    <Wrapper>
      <form onSubmit={onSubmit}>
        <Cols>
          <Col>
            <Radio
              name="type"
              value="COMPANY"
              label="Firma"
              checked={contact.type === 'COMPANY'}
              onChange={onTypeChange}
            />
            <Radio
              name="type"
              value="PERSON"
              label="Person"
              checked={contact.type === 'PERSON'}
              onChange={onTypeChange}
            />
          </Col>
        </Cols>
        {contact.type === 'COMPANY' && (
          <Cols>
            <Col>
              <Label>Name*</Label>
              <Input
                type="text"
                required="required"
                value={contact.name}
                onChange={(e) => onChangeContact('name', e.target.value)}
              />
            </Col>
          </Cols>
        )}
        {contact.type === 'PERSON' && (
          <Cols>
            <Col width={6}>
              <Label>Vorname*</Label>
              <Input
                type="text"
                required="required"
                value={contact.firstname}
                onChange={(e) => onChangeContact('firstname', e.target.value)}
              />
            </Col>
            <Col width={6}>
              <Label>Nachname*</Label>
              <Input
                type="text"
                required="required"
                value={contact.lastname}
                onChange={(e) => onChangeContact('lastname', e.target.value)}
              />
            </Col>
          </Cols>
        )}
        <Cols>
          <Col>
            <Label>Strasse/Nr.*</Label>
            <Input
              type="text"
              required="required"
              value={contact.street}
              onChange={(e) => onChangeContact('street', e.target.value)}
            />
          </Col>
        </Cols>
        <Cols>
          <Col width={3}>
            <Label>PLZ*</Label>
            <Input
              type="text"
              required="required"
              value={contact.zip}
              onChange={(e) => onChangeContact('zip', e.target.value)}
            />
          </Col>
          <Col width={9}>
            <Label>Ort*</Label>
            <Input
              type="text"
              required="required"
              value={contact.city}
              onChange={(e) => onChangeContact('city', e.target.value)}
            />
          </Col>
        </Cols>
        <Cols>
          <Col>
            <Label>Land*</Label>
            <Select
              onChange={(selected) => {
                onChangeContact('countryId', selected.value)
              }}
              required="required"
              options={countryOptions}
              selectedOption={selectedCountry}
            />
          </Col>
        </Cols>
        <Cols>
          <Col width={6}>
            <Label>E-Mail</Label>
            <Input
              type="text"
              value={contact.email}
              onChange={(e) => onChangeContact('email', e.target.value)}
            />
          </Col>
          <Col width={6}>
            <Label>Telefon</Label>
            <Input
              type="text"
              value={contact.phone}
              onChange={(e) => onChangeContact('phone', e.target.value)}
            />
          </Col>
        </Cols>
        <Button light link onClick={onCancel}>
          Abbrechen
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button link type="submit">
          Erstellen
        </Button>
      </form>
    </Wrapper>
  )
}

AddContact.propTypes = {
  onCancel: PropTypes.func.isRequired,
}

export default AddContact
