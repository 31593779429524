import React, { useState } from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { Link, Switch, Route } from 'react-router-dom'
import { isPast } from 'date-fns'

import contractGraphQL from './graphql/contract'
import ContractsNav from './ContractsNav'
import ContractsActive from './ContractsActive'
import ContractsArchive from './ContractsArchive'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Container from '../Container'
import { useLoading } from '../../utils/loading'
import Intro from './Intro'
import SyncButton from './SyncButton'
import PrintButton from './PrintButton'
import InvoiceButton from './InvoiceButton'
import Filters from './Filters'
import Invoices from './invoices'
import Sync from './Sync'
import { useAuth } from '../../utils/auth'

const AddButton = styled(Link)`
  height: 46px;
  width: 46px;
  background: #000;
  border-radius: 46px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25);
  color: #fff;
  overflow: hidden;
  margin: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Meta = styled.div`
  display: flex;
  align-items: center;
`

const MetaItem = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-right: 32px;
  }
`

export const getContractsQuery = gql`
  query GetContracts {
    getContracts {
      ...Contract
    }
  }
  ${contractGraphQL}
`

const Contracts = ({ search }) => {
  const [auth] = useAuth()

  const [filters, setFilters] = useState({
    licenses: true,
    services: true,
    margin: true,
  })
  const { data, error, loading } = useQuery(getContractsQuery)

  const [, setLoading] = useLoading()

  if (loading) {
    setLoading(true)
    return null
  } else {
    setLoading(false)
  }

  if (error) {
    return <div>Error! {error.message}</div>
  }

  const searchedContracts = data.getContracts.filter((contract) => {
    if (!search) {
      return true
    }

    const matcher = new RegExp(`${search}`, 'i')

    if (matcher.test(contract.product.name)) {
      return true
    }

    if (matcher.test(contract.contact?.name)) {
      return true
    }

    return false
  })

  const filteredContracts = searchedContracts.filter((contract) => {
    if (filters.licenses && contract.product.type === 'LICENSE') {
      return true
    }

    if (filters.services && contract.product.type === 'SERVICE') {
      return true
    }

    return false
  })

  // TODO: Split active from archive.
  const contracts = filteredContracts.reduce(
    (acc, contract) => {
      const isContractInPast =
        contract.endDate && isPast(parseInt(contract.endDate))
      if (isContractInPast) {
        acc.archive.push(contract)
      } else {
        acc.active.push(contract)
      }
      return acc
    },
    { active: [], archive: [] }
  )

  const onChangeFilter = (name, status) => {
    setFilters({
      ...filters,
      [name]: status,
    })
  }

  // Don't allow both licenses and services to be off.
  if (!filters.licenses && !filters.services) {
    setFilters({
      ...filters,
      licenses: true,
      services: true,
    })
  }

  if (auth.user.id !== '2') {
    filters.margin = false
  }

  return (
    <Container width="wide">
      <Intro
        meta={
          <Meta>
            <MetaItem>
              <Filters filters={filters} onChange={onChangeFilter} />
            </MetaItem>
            <MetaItem>
              <SyncButton />
            </MetaItem>
            <MetaItem>
              <PrintButton />
            </MetaItem>
            <MetaItem>
              <InvoiceButton />
            </MetaItem>
            <MetaItem>
              <AddButton to="/contracts/create">
                <FontAwesomeIcon icon={['far', 'plus']} />
              </AddButton>
            </MetaItem>
          </Meta>
        }
      >
        <ContractsNav
          activeCount={contracts.active.length}
          archiveCount={contracts.archive.length}
        />
      </Intro>
      <br />

      <Switch>
        <Route
          path="/contracts"
          exact
          render={(props) => (
            <ContractsActive
              {...props}
              contracts={contracts.active}
              showMargin={filters.margin}
            />
          )}
        />
        <Route
          path="/contracts/archive"
          exact
          render={(props) => (
            <ContractsArchive
              {...props}
              contracts={contracts.archive}
              showMargin={filters.margin}
            />
          )}
        />
        <Route path="/contracts/sync" exact>
          <Sync />
        </Route>
        <Route path="/contracts/invoices" exact>
          <Invoices />
        </Route>
      </Switch>
    </Container>
  )
}

Contracts.propTypes = {
  contract: PropTypes.object,
}

export default Contracts
