import gql from 'graphql-tag'

export const getTicketQuery = gql`
  query getTicket($id: ID!) {
    getTicket(id: $id) {
      id
      title
      description
      creationDate
      dueDate
      user {
        id
        firstname
        lastname
        email
      }
      assignedUser {
        id
        firstname
        lastname
        color
      }
      comments {
        id
        content
        creationDate
        user {
          id
          firstname
          lastname
          color
        }
      }
      contact {
        id
        bexioId
        name
      }
      column {
        id
        title
      }
      bexioInvoiceId
      isMarked
    }
  }
`
