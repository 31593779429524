import React, { useState, useEffect, useRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components/macro'
import gql from 'graphql-tag'
import Modal from 'react-modal'
import { DragDropContext } from 'react-beautiful-dnd'
import { useQuery, useMutation } from '@apollo/client'

import Column from './Column'
import NewTicket from './NewTicket'
import { getTicketsQuery } from './graphql'
import { filterByColumn, filterBySearch, filterByUsers } from '../utils/filter'
import { dragDropTicket } from '../utils/dragDropTicket'
import { useLoading } from '../utils/loading'

const GlobalStyle = createGlobalStyle`
  .ticket-modal__content {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    /* border: 1px solid rgb(204, 204, 204); */
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 10px;
    outline: none;
    padding: 24px;
    z-index: 1001;
    width: 680px;
    max-width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }

  .ticket-modal__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 1000;
  }
`

const Wrapper = styled.div`
  padding: 0;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  width: 100%;
`

const moveTicketMutation = gql`
  mutation MoveTicket(
    $sourceTicketId: ID!
    $sourceColumnId: ID!
    # When column is empty, we have no destinationTicketId.
    $destinationTicketId: ID
    $destinationColumnId: ID!
  ) {
    moveTicket(
      sourceTicketId: $sourceTicketId
      sourceColumnId: $sourceColumnId
      destinationTicketId: $destinationTicketId
      destinationColumnId: $destinationColumnId
    ) {
      id
      title
      sort
      column {
        id
        title
      }
    }
  }
`

Modal.setAppElement('#root')

const Board = ({ search, users }) => {
  const [moveTicket] = useMutation(moveTicketMutation)
  const { refetch, data, error, loading } = useQuery(getTicketsQuery, {
    // pollInterval: 15000,
    // fetchPolicy: 'no-cache',
    // ssr: false,
  })

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch()
    }, 15000)
    return () => {
      clearInterval(intervalId)
    }
  }, [refetch])

  const [showNewTicketModal, setShowNewTicketModal] = useState(false)
  const [, setLoading] = useLoading()

  if (loading) {
    setLoading(true)
    return null
  } else {
    setLoading(false)
  }

  if (error) {
    return `Error! ${error.message}`
  }

  // Do filtering (search and users)
  let filteredTickets = filterBySearch(data.getTickets, search)
  filteredTickets = filterByUsers(filteredTickets, users)

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    // TODO: Check permissions etc.
    // TODO: Only allow if ticket has a contact associated.
    // TODO: Show info if ticket has no positions.
    // Show confirmation when dropping into "In Verrechnung".
    if (result.destination.droppableId === '4') {
      if (
        !window.confirm(
          'Möchtest du wirklich die Rechnung für das Ticket erstellen?'
        )
      ) {
        return
      }
    }

    const source = {
      ticketId: result.draggableId,
      columnId: result.source.droppableId,
    }

    const destination = {
      // ticketId ?
      columnId: result.destination.droppableId,
    }

    // TODO: Find more performant way to get destination ticket id.
    const destinationTickets = filterByColumn(
      filteredTickets,
      result.destination.droppableId
    )

    // Info: When dropping into an empty column, we have no destination ticket (id).
    // TODO: Even when dropping to the end of a column.
    if (destinationTickets.length > 0) {
      if (result.destination.index < destinationTickets.length) {
        destination.ticketId = destinationTickets[result.destination.index].id
      } else {
        // Ticket is dragged to to the end of list.
      }
    } else {
      // Column is empty.
    }

    // Allow tickets "In Verrechnung" only to be put into "Archiv".
    if (source.columnId === '4' && destination.columnId !== '5') {
      alert('Tickets in Verrechnung können nur noch archiviert werden.')
      return
    }

    // TODO: Handle/show errors, for example if tickets has no contact.
    dragDropTicket(source, destination, moveTicket)
  }

  return (
    <Wrapper>
      <GlobalStyle />
      <DragDropContext onDragEnd={onDragEnd}>
        {data.getColumns.map((column) => (
          <Column
            column={column}
            key={column.id}
            onNewTicket={() => setShowNewTicketModal(true)}
            tickets={filterByColumn(filteredTickets, column.id)}
            hideCount={column.id === '5' ? true : false}
          />
        ))}
      </DragDropContext>
      <Modal
        isOpen={showNewTicketModal}
        contentLabel="Neues Ticket"
        className="ticket-modal__content"
        overlayClassName="ticket-modal__overlay"
        onRequestClose={() => {
          setShowNewTicketModal(false)
        }}
      >
        <NewTicket />
        <button onClick={() => setShowNewTicketModal(false)}>Abbrechen</button>
      </Modal>
    </Wrapper>
  )
}

export default Board
