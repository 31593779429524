import React from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Link from '../Link'
import { format as formatDate } from '../../utils/date'
import { format as formatCurrency } from '../../utils/currency'
import contractDuration from './utils/contractDuration'
import calculatePrice from './utils/calculatePrice'
import config from '../../config'
import { getContractsQuery } from './Contracts'

const cellStyles = css`
  padding: 0.5rem 0.5rem;
  text-align: ${(props) => props.align};

  ${(props) =>
    props.type === 'actions' &&
    css`
      @media print {
        display: none;
      }
    `}

  ${(props) =>
    props.first &&
    css`
      padding-left: 0;
    `}
`

const Table = styled.table`
  margin: 0;
  width: 100%;
`

const Th = styled.th`
  text-align: left;
  font-weight: bold;
  ${cellStyles}
`

const Td = styled.td`
  ${cellStyles}

  ${(props) =>
    props.nowrap &&
    css`
      @media (min-width: 1120px) {
        white-space: nowrap;
      }
    `}
`

const THead = styled.thead``

const TBody = styled.tbody`
  tr:last-child > td {
    padding-bottom: 1rem;
  }
`

const TFoot = styled.tfoot`
  td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-top: 1px solid #222;
    border-bottom: 3px double #222;
    font-weight: bold;
  }
`

const Buttons = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

const buttonStyles = css`
  overflow: hidden;
  margin: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
  font-size: 13px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin-left: 0.25rem;
  background: transparent;
  border: 0;
  color: ${config.colors.primary};

  &:first-of-child {
    margin-left: 0;
  }
`

const EditButton = styled(Link)`
  ${buttonStyles}
`

const DeleteButton = styled.button`
  ${buttonStyles}
`

const deleteContractMutation = gql`
  mutation deleteContract($id: ID!) {
    deleteContract(id: $id) {
      id
    }
  }
`

const TableRow = ({ row, showMargin, showContact, showDelete }) => {
  const price = calculatePrice(row.product.price, row.marginPercent)
  const [deleteContract] = useMutation(deleteContractMutation)

  function onDelete() {
    if (window.confirm('Willst du den Vertrag wirklich löschen?')) {
      deleteContract({
        variables: { id: row.id },
        refetchQueries: [
          {
            query: getContractsQuery,
          },
        ],
      })
    }
  }

  return (
    <tr>
      {showContact && (
        <Td first nowrap>
          {row.contact?.bexioId ? (
            <Link to={`/contracts/contacts/${row.contact.bexioId}`}>
              {row.contact.name}
            </Link>
          ) : (
            <em>(Kontakt gelöscht)</em>
          )}
        </Td>
      )}
      <Td first={!showContact}>
        <Link to={`/contracts/products/${row.product.id}`}>
          {row.product.name}
        </Link>
      </Td>
      <Td align="right">{row.quantity}</Td>
      <Td align="right">
        {formatCurrency((row.quantity * price) / 12, 'decimal')}
      </Td>
      {showMargin && (
        <Td align="right">
          {formatCurrency(
            row.quantity * ((price - row.product.pricePurchase) / 12),
            'decimal'
          )}
        </Td>
      )}
      <Td align="right">{formatDate(row.startDate)}</Td>
      <Td align="right">{contractDuration(row.duration)}</Td>
      <Td align="right">{row.endDate ? formatDate(row.endDate) : '-'}</Td>
      <Td type="actions">
        <Buttons>
          <EditButton to={`/contracts/${row.id}`}>
            <FontAwesomeIcon icon={['far', 'pencil']} />
          </EditButton>
          {showDelete && (
            <DeleteButton onClick={onDelete}>
              <FontAwesomeIcon icon={['far', 'trash']} />
            </DeleteButton>
          )}
        </Buttons>
      </Td>
    </tr>
  )
}

const ContractTable = ({
  data,
  showContact = true,
  showMargin = true,
  showDelete = false,
}) => {
  const initialAcc = {
    quantity: 0,
    price: 0,
    margin: 0,
  }

  const sums = data.reduce((acc, row) => {
    const price = calculatePrice(row.product.price, row.marginPercent)
    return {
      quantity: acc.quantity + row.quantity,
      price: acc.price + row.quantity * price,
      margin: acc.margin + row.quantity * (price - row.product.pricePurchase),
    }
  }, initialAcc)

  return (
    <Table cellPadding={0} cellSpacing={0} border={0}>
      <THead>
        <tr>
          {showContact && <Th first>Kunde</Th>}
          <Th first={!showContact}>Vertrag</Th>
          <Th align="right">Anzahl</Th>
          <Th align="right">
            Betrag <small>mtl.</small>
          </Th>
          {showMargin && (
            <Th align="right">
              Marge <small>mtl.</small>
            </Th>
          )}
          <Th align="right">Start</Th>
          <Th align="right">Laufzeit</Th>
          <Th align="right">Ende</Th>
          <Th type="actions" />
        </tr>
      </THead>
      <TBody>
        {data.map((row) => {
          return (
            <TableRow
              key={row.id}
              row={row}
              showMargin={showMargin}
              showContact={showContact}
              showDelete={showDelete}
            />
          )
        })}
      </TBody>
      <TFoot>
        <tr>
          {showContact && <td />}
          <td />
          <Td align="right">{sums.quantity}</Td>
          <Td align="right">{formatCurrency(sums.price / 12)}</Td>
          {showMargin && (
            <Td align="right">{formatCurrency(sums.margin / 12)}</Td>
          )}
          <td />
          <td />
          <td />
          <Td type="actions" />
        </tr>
      </TFoot>
    </Table>
  )
}

export default ContractTable
